:root {
    --bg: #9b5e6f;
    --heading: #852942;
    --text: #f5f5f5;
    --footer: #2e2a2b;
    --body-margin: 10rem;
}

@media (max-width: 500px) {
    :root {
        --body-margin: 2em;
    }
}

html {
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
    background: linear-gradient(var(--bg), var(--heading));
}

h1, h2 {
    font-weight: bold;
    font-style: italic;
    font-size: 4em;
    color: var(--heading);
    margin: 1.5rem 0;
}

h3 {
    font-size: 3rem;
    color: var(--heading);
    font-weight: bold;
    font-style: italic;
}

h3.alt {
    color: var(--bg);
    font-weight: bold;
    font-style: italic;
}

h4 {
    font-size: 2rem;
    color: var(--text);
}

p {
    color: var(--text);
}

a {
    text-decoration: none;
    color: var(--text);
}

small {
    color: var(--text);
}

button {
    border: var(--text) 2px solid;
    border-radius: .5rem;
    background-color: transparent;
    padding: 1rem 2rem;
    color: var(--text);
    font-weight: bolder;
    margin: 2rem 0;
    cursor: pointer;
    font-size: 1.2rem;
}


section {
    padding: calc(var(--body-margin) / 2) 0 calc(var(--body-margin) / 2) 0;
    /*background-color: var(--bg);*/
    overflow: hidden;
}

img {
    max-width: 100%;
}

footer {
    background-color: var(--footer);
}